@import 'app/shared/styles.module.scss';
@import 'app/shared/colors.module.scss';

$containerMaxWidth: 800px;

.container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/bg-image.png');
  background-repeat: no-repeat;
  background-size: 140% 100%;
  background-position: center;
}

.mainHeader {
  @extend .header1;
  margin: 150px 0 40px 0;
  width: 90%;
}

.inputContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.inputTitle {
  @extend .header3;
}

@media only screen and (min-width: 900px) {
  .container {
    margin: auto;
    background-size: 100% 100%;
    max-width: $containerMaxWidth;
  }
  .mainHeader {
    max-width: none;
  }
  .inputContainer {
    width: 100%;
  }
}
