@import 'app/shared/styles.module.scss';

.container {
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
}

.msgContainer {
  @extend .container;
  justify-content: center;
  align-items: center;
}

.nftsContainer {
  @extend .container;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.noNfts {
  @extend .body;
  text-align: center;
}

.loadingContainer {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  @extend .loading;
  text-align: center;
}

.nftCard:hover {
  cursor: pointer;
}

.nft {
  width: 48%;
  height: 100%;
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nftImage {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .nftsContainer {
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }

  .nft {
    width: 24%;
  }

  .bigNft {
    width: 33%;
  }
}
