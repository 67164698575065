@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import './colors.module.scss';

$containerMaxWidth: 800px;

.header {
  font-family: Sora;
  letter-spacing: -0.28px;
  font-weight: 600;
  color: $darkBlue;
  text-align: left;
  margin: 0;
}

.body {
  font-family: Inter;
  font-size: 1rem;
  text-align: left;
  color: $darkGray;
}

.label {
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
  color: $darkGray;
  line-height: 1rem;
  letter-spacing: 0em;
  color: $gray;
  margin: 0;
}

.header1 {
  @extend .header;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 4.4rem;
  text-align: center;
}

.header2 {
  @extend .header;
  font-size: 1.4rem;
  line-height: 1.75rem;
}

.header3 {
  @extend .header;
  font-size: 1rem;
  line-height: 1.25rem;
}

.primaryText {
  @extend .body;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.28px;
}

.primaryButton {
  @extend .body;
  font-weight: 600;
  text-align: center;
  height: 2.5rem;
  border: none;
  border-radius: 6.25rem;
  background-color: $green;
  color: white;
}

.unstyledButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.loading {
  @extend .body;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0em;
}

.nftTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  padding: 12px;
}
