@import 'app/shared/styles.module.scss';

.container {
  width: calc(100% - 1rem);
  height: 4rem;
  padding-right: 1rem;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
}

.background {
  background-color: white;
}

.button {
  @extend .primaryButton;
  padding: 10px 16px;
  cursor: pointer;
}

@media only screen and (min-width: 680px) {
  .container {
    width: 90vw;
    height: 3rem;
    padding: 1rem 5%;
    position: fixed;
    top: 0;
    justify-content: flex-end;
  }
}
