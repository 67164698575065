@import 'app/shared/styles.module.scss';

.container {
  display: grid;
  grid-template-columns: 1fr;
}

.imageRow {
  display: grid;
  justify-content: center;
}

.nftTitle {
  @extend .nftTitle;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.28px;
}

.nftImage {
  width: 100%;
  box-sizing: border-box;
}

.nftAttributesHeader {
  @extend .nftTitle;
  font-family: Sora;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.nftAttributesChipContainer {
  padding-bottom: 10px;
  max-width: 576px; /* prevents whitespace on right of image */
  padding: 12px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.nftAttributesChip {
  padding: 8px;
  background: #eceff1;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.nftAttributeType {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: $gray;
  padding-bottom: 2px;
}

.nftAttributeValue {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $gray;
}

.explorerLinkContainer {
  padding: 12px;
}

.explorerLink {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: $gray;
  gap: 5px;
}

@media only screen and (min-width: 600px) {
  .nftImage {
    max-width: 600px; /* Prevents super large images */
    box-sizing: border-box;
  }
}
