@import 'app/shared/styles.module.scss';
@import 'app/shared/colors.module.scss';

.container {
  margin: 4rem auto;
  margin-top: 5rem;
  width: 100%;
}

.header {
  margin: auto;
  max-width: 800px;
}

.inputContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 32px;
}

.addressLabel {
  @extend .label;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 7px;
}

.addressContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.address {
  @extend .header3;
  color: #000;
  text-align: center;
  margin: 0;
}

.nftTitle {
  @extend .nftTitle;
}

.copyAddress {
  margin-left: 5px;
  cursor: pointer;
  color: $gray3;
}

.addressCopied {
  margin-left: 5px;
}

@media only screen and (min-width: 680px) {
  .container {
    margin-top: 6rem;
  }
}
