@import 'app/shared/styles.module.scss';
@import 'app/shared/colors.module.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  height: 48px;
  border: 1px solid $gray3;
  border-radius: 24px;
  padding-left: 20px;
  overflow: hidden;
}

.pasteText {
  height: 100%;
  margin: 0;
  padding: 0 16px;
  border-radius: 24px;
  font-weight: 500;
  color: $green;
  text-align: center;
  background-color: $gray1;
  white-space: nowrap;
  cursor: pointer;

  // Center the text
  display: flex;
  align-items: center;
}

.input {
  @extend .body;
  width: calc(100% - 50px);
  text-overflow: ellipsis;
  color: $dark;
  border: none;
  background-color: transparent;
}

.input::placeholder {
  color: $gray3;
}

.input:focus {
  outline: none;
}

.pressEnter {
  @extend .unstyledButton;
  color: $green;
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: end;
  margin-top: 10px;
}

.loading {
  @extend .loading;
  align-self: flex-end;
  margin: 0;
  margin-top: 10px;
}

@media only screen and (min-width: 900px) {
  .form {
    width: 100%;
  }
  .pasteIcon {
    margin-right: calc(((100% - $containerMaxWidth) / 2) + 10px);
  }
}
